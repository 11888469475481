<template>
    
</template>
<script setup>
  import {} from 'vant';
  import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
  import http from '../../common/bxhttp.js';

  import Menu from '../../components/Menu.vue'
  import {useRoute,useRouter} from 'vue-router'
  import api from '../../common/http.js';
 
  import {isNull,showMessage,disLabel} from '../../common/utils.js'

  function login(){
    // 保险人员
    // var phone="15011241655";
    // var type="I";
    // 用户
    // var phone="15011241656";
    // var type="U";
    // 服务人员
    var phone='15011241656';
    var type="S";

    http.post("user/web/wxopenlogin",{"code":phone,"userType":type}).then((res)=>{
            if(res.errcode=="0"){
                localStorage.setItem('token',res.token);
                localStorage.setItem('name',res.name);
                localStorage.setItem('type',res.type);
            }else{
              showMessage(res.errmsg);
            }
        });
  }
  login();
</script>
